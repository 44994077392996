'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.contacts.controller:ContactRelationsCtrl

 # @description

###
class ContactRelationsCtrl
  ### @ngInject ###
  constructor:(
    $mdDialog
    ContactManager
    $q
  ) ->
    @progress = false
    @model = {}

    init = =>
      @fields = ContactManager.getRelationForm()

      @add = add
      @cancel = cancel
      @submit = submit

    ##########

    add = ->
      $q.when(ContactManager.addRelation(@model))
        .then =>
          @submit()

    cancel = ->
      $mdDialog.cancel()

    submit = ->
      $mdDialog.hide()

    init()
angular
  .module('mundoAdmin.contacts')
  .controller 'ContactRelationsCtrl', ContactRelationsCtrl
